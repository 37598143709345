
export interface Project {
    id: number;
    name: string;
    code: string;
    vendorList: Vendor[];
    clientId: number;
    requirementList: Array<any>;
    projectRoles: ProjectRole[];
    kriyagoProjectId: number;
    requirements: Array<any>;
    logeduser: string;
    comanyAccName: string;
}

export interface CoiFile{
    file: File;
    errors: string[];
}


export interface Vendor {
    id: any;
    name: string;
    code: string;
    emailAddress: string;
    contactName: string;
    isSkip: boolean;
    projectUsers: ProjectUser[];
    coiContactUserId : number | null;
    requirementList: Array<any> | null;
    isRequestSent: boolean;
    contactCoiUser: ProjectUser | null;
    isRequestCoi : boolean;
    coiFiles : Array<CoiFile> | null;
    totalFileSize: number;
    fileErrorsCount : number;
    newlyAddedContactUser : ProjectUser | null;
}

export interface InsuranceRequirement {
    vendors: Array<Vendor>;
    projectId: number;
    requirementId: number | null;
    clientId: number;
    kriyagoProjectId: number;
    companyId: string;
    tabId: number;
}


export interface ProjectUser{
    id: number;
    name: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    vendor: Company | null;
    notes: string;
    isSkipped: boolean;
    permissionTemplateId: number | null;
    errorMessage: string;
    lastNameError: string;
}

export interface Company{
    id: number;
    name: string;
}

export interface ProjectRole {
    id: number;
    name: string;
    role: string;
    contactId: number;
    createdAt: Date;
    isActive: boolean;
    userId: number;
}

export interface AssignProject {
    id: number;
    projectName: string;
    requirementList: Array<any>;
    selectedRequirementList: Array<any>;
    additionalInsured: string;
    certificateHolder: string;
    defaultSelectedUsers :Array<any>;
    companyUsers :Array<CompanyUser>;

}

export interface ProjectModel {
    id: string;
    code: string;
    name: string;
    clientId: number;
    companyId: string;
    additionalInsured: string;
    certificateHolder: string;
    kriyagoProjectId: string;
    jonesProjectId: string;
    display_name: string;
    project_number: string;
    address: string;
    city: string;
    zip: string;
    state_code: string;
    vendorList: Vendor[];
    requirementList: Array<any>;    
    requirements: Array<any>;
    projectRoles: ProjectRole[];
    userList: Array<any>;
    additionalInsuredError: string;
    certificateHolderError: string;
}

export interface ResultModel {
    id: number;
    isSuccess: boolean;
    errorCode: string;
    error: string;
    failedUsers: CompanyUser[];
}

export interface CompanyPermissionTemplate {
    id: number;
    name: string;
    JonesPermission: string;
}

export interface CompanyUser {
    [x: string]: any;
    id: number;
    address: string;
    avatar: string;
    employeeId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    isEmployee: boolean;
    isDefaultSelected: boolean;
    isInvitedToJones: boolean;
    fullName: string;   
    companyPermissionTemplate: CompanyPermissionTemplate | null;
}

export interface TabItem {
    id: string;
    name: string;
    data: any;
    selected : boolean;
    component: React.FC<any> | null;
}

export interface JonesUserInviteModel {
    projectId: string;
    companyId: string;
    mode : any;
    user: CompanyUser;
}

export interface JonesRecord {
    id: number;
    companyName: string;
    emailAddress: string;
    requirementName: string;
    displayStatus: string;
    statusLoopkupElement: number;
    expirationDate: Date;
    jonesuserexit:boolean;
    complainceReportLink?: string;
   
}

export interface PermissionTemplate {
    id: number;
    name: string;
    type: string;
    projectSpecific: false;
}

export interface MissingCompanyUser {
    vendorId : number;
    email: string;
    lastName: string
    permissionTemplateId: number;
    uuid: string;
}

export interface PermissionOptionModel {
    label: string;
    options: PermissionTemplate[];
}

export interface FileUpload {
    id: number;  
    attachmentId: number;  
    fileName: string;  
    filePath: string;  
    uploadOn: any ;  
    notes: string;  
    fileGuidNumber: string;  
    file: any;  
    fileSrc: any;  
  }

  
export interface CommitmentTool {
    Companyname: string;
    commitmentnumber: string;
    commitmentstatus: string;
    executed:string;
    Vendorcode:string;
}
  export const imgMimes: string[] = [
    'image/bmp',  
    'image/jpeg',  
    'image/png',  
    'image/tiff',  
  ];
  export const receiptMimes: string = `image/bmp,image/jpeg,image/png,image/tiff,application/pdf`;