import React, { useCallback, useState } from "react";
import { Card, FormControl } from "react-bootstrap";
import { AddProject } from "./hooks";
import Select, { components } from "react-select";
import { AssignProject, ProjectModel } from "../../../models";
import classNames from 'classnames';

const MultiValue = (props: any) => {
  if (!props.index) {
    const CHIPS_LIMIT = 1;
    const validChips = props.getValue().filter((x: any) => x.id !== 0);

    if (validChips.length === CHIPS_LIMIT) {
      return <div>{validChips[0].description}</div>;
    } else if (validChips.length > CHIPS_LIMIT) {
      return (
        <div>{`${validChips.length} Requirements / ${props.options.length - 1
          } Requirements`}</div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const UserMultiValue = (props: any) => {
  if (!props.index) {
    const CHIPS_LIMIT = 1;
    const combinedList: any[] = [];
    const validChips = props.getValue().filter((x: any) => x.id !== 0);
    for (let i = 1; i < props.options.length; i++) {
      const options = props.options[i].options;
      combinedList.push(...options);
    }
  

    if (validChips.length === CHIPS_LIMIT) {
      return (
        <div>{`${validChips[0].firstName} ${validChips[0].lastName} `}</div>
      );
    } else if (validChips.length > CHIPS_LIMIT) {
      return (
        <div>{`${validChips.length} Users / ${combinedList.length
          } Users`}</div>
      );
    } else {
      return null;
    }
  } 
  
  else {
    return null;
  }
};

function CreateProject(props: { procoreProject: AssignProject }) {
  const { submitting, submit, permissionName } = AddProject();
  const companyId = sessionStorage.getItem("companyId");
  const { procoreProject } = props;
  const [joneProject, setJoneProject] = useState<ProjectModel>({
    id: procoreProject.id.toString(),
    code: "",
    name: "",
    clientId: 0,
    companyId: companyId ? companyId?.toString() : '0',
    additionalInsured: procoreProject.additionalInsured,
    certificateHolder: procoreProject.certificateHolder,
    kriyagoProjectId: "",
    jonesProjectId: "",
    display_name: "",
    project_number: "",
    address: "",
    city: "",
    zip: "",
    state_code: "",
    vendorList: [],
    requirementList: [],
    requirements: [],
    projectRoles: [],
    userList: procoreProject?.defaultSelectedUsers ?? [],
    additionalInsuredError: "",
    certificateHolderError: "",
   
    
  });

  const CustomOption = ({
    children,
    hasValue,
    getValue,
    innerProps,
    ...props
  }: any) => {
    return (
      <components.Option {...props}>
        <div
          style={{ zIndex: 1 }}
          className="d-flex"
          onClick={() => {
            if (props.data.id === 0 && props.isSelected) {
              handleInsuranceReqChange([], {
                action: "clear",
                removedValue: null,
              });
            } else if (props.data.id && props.isSelected) {
              handleInsuranceReqChange([], {
                action: "clear",
                removedValue: props.data,
              });
            } else if (innerProps && innerProps.onClick) {
              innerProps.onClick();
            }
          }}
        >
          <CheckboxCustom checked={props.isSelected} />

          <label>{props.value}</label>
        </div>
      </components.Option>
    );
  };

  const CustomUserOption = ({
    children,
    hasValue,
    getValue,
    innerProps,
    ...props
  }: any) => {
    const isDisabled = props.data?.role === 'Admin';
  
    return (
      <components.Option {...props} isDisabled={isDisabled} 
      className={isDisabled ? 'disabled-option' : ''} >
        <div
          style={{ zIndex: 1, pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}
          className="d-flex"
          onClick={() => {
            if (props.data.id === 0 && props.isSelected) {
              handleUserChange([], { action: "clear", removedValue: null });
            } else if (props.data.id && props.isSelected) {
              handleUserChange([], {
                action: "clear",
                removedValue: props.data,
              });
            } else if (innerProps && innerProps.onClick) {
              innerProps.onClick();
            }
          }}
        >
          <CheckboxCustom checked={props.isSelected} disabled={isDisabled} />

          {props.data.id === 0 ? (
            <label className="label-wrapper">{props.value}</label>
          ) : (            
            <label className="label-wrapper">        
            {props.value} <span title={`${props.data?.emailAddress} ${props.data?.role ? props.data?.role :''}`}>({props.data?.emailAddress && props.data?.emailAddress.length > 10 && props.data?.role ? `${props.data.emailAddress.substring(0, 15)}${props.data?.emailAddress.length > 10 ? '...' : ''}` : props.data?.emailAddress}) {props.data?.role ? <span style={{ color: 'silver' }}>&nbsp;&#8226; {props.data.role}</span> : ''}</span>

          </label>
   
          )}
        </div>
      </components.Option>
    );
  };

  const CheckboxCustom = ({ checked }: any) => {
    return (
      <label className="custom-container">
        <input type="checkbox" checked={checked} onChange={() => { }} />
        <span className="checkmark"></span>
      </label>
    );
  };

  const handleInsuranceReqChange = useCallback(
    (selectedRequirement: any, { action, removedValue }: any) => {
      if (selectedRequirement && selectedRequirement.length) {
        if (action === "deselect-option") {
          selectedRequirement = selectedRequirement.filter(
            (x: any) => x.id !== 0
          );
        }
        const selectAllIndex = selectedRequirement.findIndex(
          (x: any) => x.id === 0
        );

        if (selectAllIndex >= 0) {
          const selectableRequirements = procoreProject.requirementList.filter(
            (x) => true
          );
          setJoneProject({
            ...joneProject,
            requirementList: selectableRequirements,
          });
        } else {
          setJoneProject({
            ...joneProject,
            requirementList: selectedRequirement,
          });
        }
      } else if (removedValue) {
        if (removedValue.id === 0) {
          setJoneProject({
            ...joneProject,
            requirementList: [],
          });
        } else {
          const currenRequirements = joneProject.requirementList.filter(
            (x: any) => x.id !== removedValue.id
          );

          setJoneProject({
            ...joneProject,
            requirementList: currenRequirements,
          });
        }
      } else if (action === "clear") {
        setJoneProject({
          ...joneProject,
          requirementList: [],
        });
      }
    },
    [setJoneProject, joneProject, procoreProject.requirementList]
  );

  const handleUserChange = useCallback(
    (selectedUser: any, { action, removedValue }: any) => {
      if (selectedUser && selectedUser.length) {
        if (action === "deselect-option") {
          selectedUser = selectedUser.filter((x: any) => x.id !== 0);
        }
        const selectAllIndex = selectedUser.findIndex((x: any) => x.id === 0);

        if (selectAllIndex >= 0) {
          // const selectableUsers = procoreProject.companyUsers.filter(
          //   (x) => true
          // );
         let  selectableUsers=[];
         selectableUsers.push( procoreProject.companyUsers[0])         
          for (let i = 1; i < procoreProject.companyUsers.length; i++) {
            const options = procoreProject.companyUsers[i].options;
            selectableUsers.push(...options);
          }
          setJoneProject({
            ...joneProject,
            userList: selectableUsers,
          });
        } else {
          setJoneProject({
            ...joneProject,
            userList: selectedUser,
          });
        }
      } else if (removedValue) {
        if (removedValue.id === 0) {
          setJoneProject({
            ...joneProject,
            userList: [],
          });
        } else {
          const currentUsers = joneProject.userList.filter(
            (x: any) => x.id !== removedValue.id
          );
          setJoneProject({
            ...joneProject,
            userList: currentUsers,
          });
        }
      } else if (action === "clear") {
        setJoneProject({
          ...joneProject,
          userList: [],
        });
      }
    },
    [setJoneProject, joneProject, procoreProject]
  );

  const onInsuranceReqFilter = useCallback((option: any, searchText: any) => {
    if (
      option.data.description?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const onUserFilter = useCallback((option: any, searchText: any) => {
    if (
      option.data.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.lastName?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const setSelectedBackColor = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: "white",
        fontSize: "14px !important",
      };
    },
  };

  const additionalInsuredChange = (event: any) => {
    setJoneProject({
      ...joneProject,
      additionalInsured: event.target.value,
    });
  };

  const certificateHolderChange = (event: any) => {
    setJoneProject({
      ...joneProject,
      certificateHolder: event.target.value,
    });
  };

  const assignProject = useCallback(() => {
    const validUsers = joneProject?.userList?.filter((x: any) => x.id > 0);
    const validRequirements = joneProject?.requirementList?.filter(
      (x: any) => x.id > 0
    );

    const data = {
      ...joneProject,
      userList: validUsers ? validUsers : [],
      requirementList: validRequirements ? validRequirements : [],
    };

    submit(data);
  }, [joneProject, submit]);

  const validateAdditionalInsured = useCallback((value: string) => {
    if (value.trim().length < 2) {
      setJoneProject({
        ...joneProject,
        additionalInsuredError: "Additional insured should be at least 2 characters."
      });

    } else if (value.length > 10000) {
      setJoneProject({
        ...joneProject,
        additionalInsuredError: "Additional insured should not be more than 10000 characters."
      });
    } else {
      setJoneProject({
        ...joneProject,
        additionalInsuredError: ""
      });
    }
  }, [joneProject])

  const validateCertificateHolder = useCallback((value: string) => {
    if (value.trim().length < 2) {
      setJoneProject({
        ...joneProject,
        certificateHolderError: "Certificate holder should be at least 2 characters."
      });
    } else if (value.length > 10000) {
      setJoneProject({
        ...joneProject,
        certificateHolderError: "Certificate holder name should not be more than 10000 characters."
      });
    } else {
      setJoneProject({
        ...joneProject,
        certificateHolderError: ""
      });
    }
  }, [joneProject])

  
  


  return (
    <>
      <div className="container container-wrapper p-4">
        <div className="row">
          <div className="col-sm-12">
            <Card
              style={{
                width: 650,
                margin: "0 auto",
                boxShadow: "0px 1.77812px 3.55625px rgb(0 0 0 / 11%)",
              }}
            >
              <Card.Body className="p-0">
                <div className="panel-body" style={{ padding: "1.6rem" }}>
                  <div className="custom-header">
                    Create {procoreProject ? procoreProject.projectName : ""} in
                    Jones
                  </div>

                  <div
                    className="header-details"
                    style={{ lineHeight: "19.5px" }}
                  >
                    <p className="pt-1 mb-4">
                      Please fill out the fields below before continuing.
                    </p>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-5 ">
                      <label className="custom-lable">
                        Select team member for the project :{" "}
                        <span className="required-icon"> *</span>
                      </label>
                    </div>
                    <div className="col-sm-7 ">
                      <Select
                        key={procoreProject?.companyUsers?.length}
                        isMulti
                        isClearable
                        isSearchable
                        hideSelectedOptions={false}
                        classNamePrefix="name-select"
                        className="custom-select-input company-user-dd"                                       
                        getOptionLabel={(option) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        getOptionValue={(option) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        closeMenuOnSelect={false}
                        noOptionsMessage={() => null}
                        placeholder={""}
                        value={joneProject.userList}
                        options={procoreProject?.companyUsers}
                        onChange={handleUserChange}                        
                        filterOption={onUserFilter}
                        components={{
                          MultiValue: UserMultiValue,
                          Option: CustomUserOption,
                        }}
                        isOptionSelected={(option) => {
                          return joneProject.userList.includes(option);
                        }}
                        isOptionDisabled={(option) => option.role === 'Admin'}
                        styles={setSelectedBackColor}
                      />
                    </div>
                  </div>

                  <div
                    className="header-details pt-1"
                    style={{ lineHeight: "19.5px" }}
                  >
                    <p className="mt-2 mb-4 pb-2">
                      List of users with{" "}
                      {permissionName ? `'${permissionName}'` : ""} company
                      level permissions.
                    </p>
                  </div>
                 {/*  <div className="mt-5 row">
                    <div className="col-sm-5 ">
                      <label className="custom-lable">
                        Select insurance requirements for the project:{" "}
                        <span className="required-icon">*</span>
                      </label>
                    </div>
                    <div className="col-sm-7 ">
                      <Select
                        isMulti
                        isClearable
                        isSearchable
                        hideSelectedOptions={false}
                        classNamePrefix="name-select"
                        className="custom-select-input"
                        getOptionLabel={(option) => `${option.description}`}
                        getOptionValue={(option) => `${option.description}`}
                        closeMenuOnSelect={false}
                        noOptionsMessage={() => null}
                        placeholder={""}
                        value={joneProject.requirementList}
                        options={procoreProject?.requirementList}
                        onChange={handleInsuranceReqChange}
                        filterOption={onInsuranceReqFilter}
                        components={{
                          MultiValue: MultiValue,
                          Option: CustomOption,
                        }}
                        isOptionSelected={(option) => {
                          return joneProject.requirementList.includes(option);
                        }}
                        styles={setSelectedBackColor}
                      />
                    </div>
                  </div> */}
                 {/*  <div
                    className="header-details"
                    style={{ lineHeight: "19.5px" }}
                  >
                    <p>
                      The vendors in this project will be checked against the
                      requirements you select for Additional Insureds and
                      Certificate Holder below.
                    </p>
                  </div> */}
                  <div className="row pt-2">
                    <div className="col-sm-6 custom-textareaAI ">
                      <label className="custom-lable">
                        Additional insured:{" "}
                        <span className="required-icon"> *</span>
                      </label>
                     
                      <FormControl
                        as="textarea"
                        className={classNames('custom-textarea ', joneProject.additionalInsuredError ? 'invalid-control' : '')}
                        value={joneProject.additionalInsured}
                        onChange={additionalInsuredChange}
                        onBlur={(e: any) => { validateAdditionalInsured(e.target.value) }}
                        rows={6}
                      ></FormControl>
                      {joneProject.additionalInsuredError ?
                        <span className="text-danger">
                          {joneProject.additionalInsuredError}
                        </span> : null}
                    </div>
                    <div className="col-sm-6" style={{ paddingLeft: "27px" }}>
                      <label className="custom-lable">
                        Certificate holder:{" "}
                        <span className="required-icon"> *</span>
                        <p className="header-details mb-1">
                           The project name and number will automatically be filled.                      
                        </p>
                      </label>
                      <FormControl
                        as="textarea"
                        className={classNames('custom-textarea ', joneProject.certificateHolderError ? 'invalid-control' : '')}
                        value={joneProject.certificateHolder}
                        onChange={certificateHolderChange}
                        onBlur={(e: any) => { validateCertificateHolder(e.target.value) }}
                        rows={5}
                      ></FormControl>
                      {joneProject.certificateHolderError ? <span className="text-danger">
                        {joneProject.certificateHolderError}
                      </span> : null}
                    </div>
                  </div>
                  <div
                    className="header-details"
                    style={{ lineHeight: "19.5px" }}
                  >
                    <p className="mt-2">
                      Insurance requirements are pre-configured within Jones and will automatically populate to the project. 
                      
                    </p>
                  </div>
                </div>

              </Card.Body>
              <Card.Footer className="cusrom-card-footer">
                <div className="row clear button-group no-gutters ">
                  <div className="col-sm-2 text-left pl-2">
                    <p className="required-field">* required field</p>
                  </div>
                  <div className="col-sm-10 text-right p-2 pr-2">
                    {submitting ? (
                      <img src={"/images/loader.gif"} alt="Loading" />
                    ) : (
                      <button
                        className="btn btn-secondary ml-3 btn-cutom"
                        disabled={
                          /* !joneProject.requirementList || !joneProject.requirementList.length || */
                          // !joneProject.userList || !joneProject.userList.length ||
                          // !joneProject.additionalInsured.trim() || !joneProject.certificateHolder.trim() ||
                          // joneProject.additionalInsured.length > 10000 || joneProject.additionalInsured.trim().length < 2 ||
                          // joneProject.certificateHolder.length > 10000 || joneProject.certificateHolder.trim().length < 2
                          !joneProject.userList || !joneProject.userList.length ||
                          (!joneProject.additionalInsured || !joneProject.additionalInsured.trim()) || 
                          (!joneProject.certificateHolder || !joneProject.certificateHolder.trim()) ||
                          joneProject.additionalInsured.length > 10000 || 
                          (joneProject.additionalInsured.trim().length < 2 && joneProject.additionalInsured.trim() !== "") ||
                          joneProject.certificateHolder.length > 10000 || 
                          (joneProject.certificateHolder.trim().length < 2 && joneProject.certificateHolder.trim() !== "")
                        }
                        onClick={assignProject}
                      >
                        {submitting ? "Submitting" : "Create Project"}
                      </button>
                    )}
                  </div>
                </div>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateProject;
